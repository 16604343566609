<template>
	<div class="part-catalogue">
		<!-- Loading Component -->
		<div class="loading"
			v-if="loading">
			<v-spacer/>

			<v-progress-circular
				:size="100"
				color="primary"
				indeterminate/>

			<v-spacer/>
		</div>

		<div class="catalogue-container">
			<div class="top-buttons">
				<!-- Navigation Buttons Left -->
				<div class="history-row">
					<v-btn 
						style="margin-right: 10px;"
						width="150"
						:disabled="previousIDs.length == 0 || this.previousIDs.slice(-1)[0].parentID == -1"
						small
						color="primary"
						@click="backTier(true)">
						<v-icon>mdi-skip-backward</v-icon> Reset
					</v-btn>

					<v-btn 
						width="150"
						:disabled="previousIDs.length == 0 || this.previousIDs.slice(-1)[0].parentID == -1"
						small
						color="success"
						@click="backTier(false)">
						<v-icon>mdi-step-backward</v-icon> Up One Level
					</v-btn>
				</div>

				<!-- Category History Slide Group -->
				<div class="history-row"
					style="margin-left: 10px; margin-right: 10px; width: 100%; height: 100%; overflow: hidden;"
					v-if="previousIDs.length > 1">
					<v-slide-group
						ref="history"
						show-arrows>
						<v-slide-item
							v-for="previous, index in previousIDs"
							:key="'previous_' + index"
        					v-slot="{ active, toggle }">
							<div class="history-row"
								@click="selectBackTier(index)">
								<v-icon v-if="index > 0">
									mdi-arrow-right-thin
								</v-icon>

								<div class="history">
									{{ previous.parentName }}
								</div>
							</div>
						</v-slide-item>
					</v-slide-group>
				</div>
				
				<v-spacer v-if="previousIDs.length < 2"/>
				
				<!-- Filter Input -->
				<div style="width: 250px; min-width: 250px;">
					<v-text-field
						v-model="filter"
						label="Filter Results"
						hide-details="auto"
						clearable
						outlined
						dense/>

				</div>
			</div>
		
			<!-- Tier view Container -->
			<div class="views-container"
				v-if="view == 'tier'">
				<!-- Tier View Components -->
				<div v-for="tier, index in tiers"
					:key="'tier_' + index"
					v-show="!filterTier(tier.name)">
					<div class="view"
						:style="`background-image: url('${imageAddress(tier.image)}'); background-size: contain;`" 
						@click="selectTier(tier.id, tier.name)">
						<v-spacer />

						<div class="view-info">
							<h5>{{ tier.name}}:</h5>

							<div style="overflow-x: hidden; overflow-y: auto; font-size: 1.2vh;">
								{{ tier.description }}
							</div>
						</div>
					</div>
				</div>

				<!-- Part View Components -->
				<div v-for="part, index in parts"
					:key="'part_' + index"
					v-show="!filterPart(part.part, part.description)">
					<div class="view"
						:style="`background-image: url('${imageAddress(part.image)}'); background-size: contain;`" 
						@click="selectPart(part.partid)">
						<v-spacer />

						<div class="view-info">
							<h3>Part: {{ part.part}}</h3>

							<v-divider style="border: 1px solid white; width: 100%; margin-bottom: 5px;"/>

							<div style="overflow-x: hidden; overflow-y: auto; font-size: 1.5vh;">
								{{ part.description }}
							</div>
						</div>
					</div>
				</div>

				<div class="view"
					v-if="parts.length == 0 && tiers.length == 0"
					:style="`background-image: url('${imageAddress('')}'); background-size: contain;`">
					<v-spacer />
					
					<div class="view-info">
						<h3>Work in progress:</h3>

						<div style="overflow-x: hidden; overflow-y: auto; font-size: 1.5vh;">
							Options will appear as we have time to set them up.
						</div>
					</div>
				</div>
			</div>


			<!-- Part View Container -->
			<div class="parts-container"
				v-if="view == 'part'">
				<component
					:is="'PartSearch'" 
					:hideSearch="true"
					:partID="partID"/>
			</div>

		</div>
	</div>
</template>

<script>
//import PartView from './part_view'				// Imports Parts Search component which adds part searching functionality.
import PartSearch from '../part_search'				// Imports Parts Search component which adds part searching functionality.

export default {
	name: 'PartsCatalogue',
	components: {
		//PartView,
		PartSearch
	},
	props: {
	},
	data () {
		return {
			loading: false,
			previousIDs: [],
			parentID: -1,
			results: [],
			tiers: [],
			parts: [],
			back: false,
			partID: -1,
			view: "tier",
			filter: "",
		}
	},
	methods: {
		getTier() {
			this.results = []
			this.loading = true;

			var formData = new FormData();

			formData.append("parentid", this.parentID)

			axios.post(`${this.$config.api}/api.php?cmd=getTier`,
			formData,
			{
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then(response => {
				// API FAILED
				if (!response.data.success){
					this.loading = false;
					console.log(response.data)
					return
				}

				if (!this.back) {
					var previous = {
						parentID: this.parentID,
						parentName: this.parentID == -1 ? "Start" : this.parentName
					}

					this.previousIDs.push(previous)
				} else {
					this.back = false
				}

				this.clear()
				this.results = response.data.results
				this.sortResults()
				//console.log(response.data)
				this.loading = false
				this.filter = ""
				setTimeout(this.scrollHistory, 200);
			})
			.catch(error => {
				if (error.response) {
					// The request was made and the server responded with a status code
					// that falls out of the range of 2xx
					console.log("Server returned status code:")
					console.log(error.response.data)
					console.log(error.response.status)
					console.log(error.response.headers)
				} else if (error.request) {
					// The request was made but no response was received
					// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
					// http.ClientRequest in node.js
					console.log("Server didn't respond:")
					console.log(error.request)
				} else {
					// Something happened in setting up the request that triggered an Error
					console.log("Unknown Error:")
					console.log('Error', error.message)
				}
			})
		},
		sortResults() {
			for (var result of this.results) {
				if (result.toplevel) {
					this.tiers.push(result)
					continue
				}

				if (result.tierid != -1) {
					this.tiers.push(result)
					continue
				}

				if (result.partid != -1) {
					this.parts.push(result)
					continue
				}

			}
		},
		selectTier(parentID, parentName) {
			this.parentID = parentID
			this.parentName = parentID == -1 ? "Start" : parentName

			this.getTier()
		},
		selectBackTier(index) {
			var slice = (this.previousIDs.length - (index + 1))

			if (slice > 0 ) {
				this.parentID = this.previousIDs[index].parentID
				this.parentName = this.previousIDs[index].parentName

				this.previousIDs.length = index + 1
			}

			this.view = "tier"
			this.back = true
			this.getTier()
		},
		selectPart(partID) {
			this.partID = partID
			
			if (this.partID != -1) {
				this.view = 'part'
			}
		},
		backTier(toStart) {
			// toStart means all the way back to the top level tiers.
			if (!toStart) {
				// If in parts view, switch back to tier view only. No need to go back a tier.
				if (this.view == "part") {
					this.view = "tier"
					return
				}

				this.previousIDs.pop()
				this.parentID = this.previousIDs.slice(-1)[0].parentID
				this.back = true
			} else {
				this.previousIDs = []

				this.parentID = -1
				this.parentName = "Top"
				this.view = "tier"
			}

			this.getTier()
		},
		imageAddress(image) {
			if (image.length > 0){
				return `${this.$config.images}${image}`
			}

			return `${this.$config.images}No_Image_Available.jpg`
		},
		clear() {
			this.results = []
			this.tiers = []
			this.parts = []
		},
		scrollHistory() {
			if (this.$refs.history == null ) {
				return
			}
			
			var offset = this.$refs.history.$data.widths.content - this.$refs.history.$data.widths.wrapper

			if (offset > 0) {
				this.$refs.history.$data.scrollOffset = offset
			} else {
				this.$refs.history.$data.scrollOffset = 0
			}
		},
		filterTier(name) {
			if (this.filter == null) {
				return false
			}

			if (name.toLowerCase().includes(this.filter.toLocaleLowerCase())) {
				return false
			}

			return true
		},
		filterPart(part, description) {
			if (this.filter == null) {
				return false
			}

			if (part.toLowerCase().includes(this.filter.toLocaleLowerCase()) || description.toLowerCase().includes(this.filter.toLocaleLowerCase())) {
				return false
			}

			return true
		},
	},
	computed: {
	},
	watch: {
	},
	mounted() {
		this.getTier()
	},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	.part-catalogue {
		height: 100%;
		width: 100%;
		overflow: hidden;
	}

	.catalogue-container {
		display: flex;
		flex-direction: column;
		height: 100%;
		width: 100%;
		padding: 5px;
		border: 1px solid silver;
		background-color: white;
		overflow-x: hidden;
		overflow-y: auto;
	}

	.loading {
		display: flex;
		flex-direction: row;
		align-items: center;
		position: absolute;
		top: 33px;
		left: 0px;
		background-color: rgba(0,0,0,.25);
		height: 100%;
		width: 100%;
		text-align: center;
		z-index: 5;
	}

	.views-container {
		display: grid;
		grid-template-columns: repeat(6, 1fr);
		justify-content: center;
		gap: 10px;
  		flex-grow: 1;
		height: 100%;
		width: 100%;
		padding: 10px;
		overflow-x: hidden;
		overflow-y: scroll;
		border: 1px solid silver;
		border-radius: 5px;
	}

	.parts-container {
		display: flex;
		flex-direction: column;
  		flex-grow: 1;
		height: 100%;
		width: 100%;
		overflow: hidden;
		border-radius: 5px;
	}

	.view {
		display: flex;
		flex-direction: column;
		align-items: start;
		width: 15.5vw;
		height: 18vw;
		border: 1px solid gray;
		border-radius: 5px;
		background-color: white;
		object-fit: contain;
	}

	.view:hover {
		cursor: pointer;
		background-color: rgba(158, 184, 255, 0.4);
	}

	.top-buttons {
		display: flex;
		flex-direction: row;
		flex-shrink: 1;
		align-items: center;
		width: 100%;
		margin-bottom: 5px;
	}

	.image-preview {
		background-color: white;
		max-width: 95%;
		max-height: 50%;
		object-fit: contain;
	}

	.view-info {
		display: flex;
		flex-direction: column;
		align-items: start;
		width: 100%;
		height: 30%;
		padding: 5px;
		border-top: 1px solid gray;
		border-radius: 5px;
		color: white;
		background-color: rgba(0,0,0,0.5);
	}

	.history {
		display: flex;
		flex-direction: row;
		align-items: center;
		height: 100%;
		padding: 5px;
		border: 1px solid silver;
		border-radius: 5px;
		cursor: pointer;
		font-size: 1.3vh;
		background-color: azure;
	}

	.history:hover {
		background-color: lightcyan;
	}

	.history-row {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.v-slide-group__next, .v-slide-group__prev {
		flex: 0 1 0px !important;
		min-width: 0px !important;
	}
</style>