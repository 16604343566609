<template>
	<div class="part-search">
		<!-- Multiple Search Options Modal -->
		<div class="options-container"
			v-if="results.length > 0">
			<v-spacer />
			
			<div class="options-inner elevation-6">
				<div class="options-title">
					Multiple or partial matches. Please select one:

					<div class="close-button">
						<v-btn
							color="default"
							icon
							@click="results = []">
							<v-icon
								large
								color="error">
								mdi-close-circle
							</v-icon>
						</v-btn>
					</div>
				</div>

				<div class="options-results">
					<div 
						class="options-result"
						v-for="option, index in results"
						:key="index"
						@click="searchPart(option.id)">
						<div class="option-part">
							{{ option.part }}
						</div>
						 
						<div class=" option-description">
							{{option.description }}
						</div>
					</div>
				</div>
			</div>

			<v-spacer />
		</div>

		<!-- Loading Component -->
		<div class="loading"
			v-if="loading">
			<div class="progress">
				<v-progress-circular
					:size="100"
					color="primary"
					indeterminate/>
			</div>
		</div>

		<!-- Top Search Bar Component -->
		<div class="search-bar"
			v-if="!hideSearch">
			<div class="search"
				style="width: 350px;"
				v-show="!descriptionSearch">
				<v-combobox
					class="fields"
					v-model="partNumber"
					:items="partNumbers"
					label="Part Number"
					ref="partInput"
					hide-details="auto"
					autocomplete="off"
					autofocus
					outlined
					dense
					clearable
					@keyup.enter.native="getParts()"/>
			</div>

			<div class="search"
				style="width: 350px;"
				v-show="descriptionSearch">
				<v-text-field
					type="text"
					v-model="partDescription"
					label="Part Description"
					hide-details="auto"
					outlined
					clearable
					dense
					@keyup.enter.native="getParts()"/>
			</div>

			<div class="search"
				style="width: 200px; margin-left: 10px;">
				<div class="show-pricing"
					v-if="this.$store.getters['authentication/GET_AUTHENTICATED']">
					<v-switch
						v-model="descriptionSearch" 
						hide-details="auto"
						label="Description Search"/>
				</div>
			</div>

			<div class="search-buttons">
				<v-btn
					class="button"
					color="primary"
					:disabled="!canSearch"
					@click="getParts">
					Search
				</v-btn>			

				<v-btn
					v-show="partInfo. part != null && partInfo.part.length > 0"
					class="button"
					color="success"
					@click="copyLink()">
					Copy Link
				</v-btn>

				<v-btn
					class="button"
					color="teal"
					dark
					@click="showUpdate()">
					Help
				</v-btn>

				<span 
					class="discontinued"
					style="margin-left: 5px;"
					v-if="this.error.length > 0">
					{{this.error}}
				</span>

				<v-spacer />

				<div class="show-pricing"
					v-if="this.$store.getters['authentication/GET_AUTHENTICATED']">
					<v-switch
						v-model="showPricing" 
						hide-details="auto"
						@change="ChangeShowPricing()"
						label="Show Pricing"/>
				</div>
			</div>
		</div>

		<!-- Content Below Search Bar -->
		<div class="part-info"
			:style="background"
			v-if="partInfo.part != null && partInfo.part.length > 0">
			<div class="left-column">
				<!-- Image Preview -->
				<div class="preview-image-box">
					<img
						class="preview-image" 
						:src="imageAddress"
						v-if="partInfo.image.length > 0"
						width="100%"/>

					<img
						class="preview-image" 
						src="../assets/No_Image_Available.jpg"
						v-if="partInfo.image.length == 0"
						width="100%"/>
				</div>
			</div>

			<div class="right-column">
				<!-- Part Details Component -->
				<div class="part-details">
					<div class="caption">
						<span class="caption-text">
							Part Information:
						</span>
					</div>

					<!-- Part Number Section -->
					<div class="info-line">
						<div class="line-title">
							Part Number:
						</div>

						<div class="line-info">
							<span :class="sellable">
								{{ this.partInfo.part }}
							</span>&nbsp;
								
							<span :class="sellable" v-if="partInfo.sellable == 0">
								*NON-SELLABLE PART*
							</span>

							<!-- Icon Section -->
							<div v-if="partInfo.pdf != null && partInfo.pdf != -1">
								<v-btn
									color="black"
									icon
									@click="showPDF(partInfo.pdf)">
									<v-icon color="#2F4F4F">
										mdi-file-pdf-box
									</v-icon>
								</v-btn>
							</div>

							<v-spacer />

							<!-- Add to cart section -->
							<div class="add-to-cart"
								v-if="showCart">
								<div class="pricing"
									v-if="this.$store.getters['authentication/GET_AUTHENTICATED'] && partInfo.sellable">
									<table style="text-align: left;"
										v-if="showPricing">
										<tr>
											<td>Retail:</td>
											<td>
												{{partInfo.retail == null || partInfo.retail == 0 ? "Contact" : `$${partInfo.retail.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}}
											</td>
										</tr>

										<tr>
											<td>Dealer:</td>
											<td>
												{{calculateDealer(partInfo.retail) == null || calculateDealer(partInfo.retail) == 0 ? "Contact" : `$${calculateDealer(partInfo.retail).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}}
											</td>
										</tr>
									</table>
								</div>

								<v-text-field
									style="width: 120px;"
									maxlength="4"
									type="number"
									min="1"
									v-model="quantity"
									:disabled="partInfo.retail== 0"
									label="Qty"
									:rules="[rules.quantity]"
									hide-details="auto"
									outlined
									dense 
									@change="quantityChanged()"/>

								<v-btn
									icon
									:disabled="partInfo.retail== 0"
									@click="addToCart()">
									<v-icon>
										mdi-cart-plus
									</v-icon>
								</v-btn>
							</div>

						</div>
					</div>

					<!-- Description Section -->
					<div class="info-line">
						<div class="line-title">
							Description:
						</div>

						<div class="line-info">
							{{ this.partInfo.description }}
						</div>
					</div>

					<!-- Supersede Section -->
					<div class="info-line"
						v-if="partInfo.supersedes != null && partInfo.supersedes.length > 0">
						<div class="line-title">
							Superseded By:
						</div>

						<div class="line-info">
							<div										
								v-for="supersede, index in partInfo.supersedes"
								:key="supersede.part + '_' + index"
								v-show="supersede.part != null & supersede.part != ''"
								@click="partClicked(supersede.id, supersede.part)">
								<v-tooltip
									right
									color="rgba(0,0,0,0)">
									<template v-slot:activator="{ on, attrs }">
										<div
											v-bind="attrs"
											v-on="on">
											<span v-if="index > 0">,&nbsp;</span>	
											<span :style="sellableStyle(supersede.sellable)">{{ supersede.part }}</span>
										</div>
									</template>

									<div class="column">
										<!-- Part description display if sellable -->
										<span 
											v-if="sellable"
											style="font-size: 14pt;color: black; max-width: 1000px;">
											{{ supersede.part }} - {{ supersede.description }}
										</span>

										<!-- Part description display if non-sellable -->
										<span
											v-if="!sellable"
											style="font-size: 14pt;color: red; max-width: 1000px;">
											{{ supersede.part }} is not available for purchase.
										</span>

										<!-- Part image display if an image is set -->
										<img
											v-if="supersede.image != null && supersede.image.length > 0"
											class="image-preview" 
											:src="supersedeImageAddress(supersede.image)" 
											height="500px" 
											width="500px" />

										<!-- Part image display if no image is set -->
										<img
											v-if="supersede.image == null || supersede.image.length == 0"
											class="image-preview" 
											src="../assets/No_Image_Available.jpg" 
											height="500px" 
											width="500px" />

										<!-- Part notes display if notes are set -->
										<div v-if="supersede.notes != null && supersede.notes.length > 0">
											<span style="font-size: 14pt;color:black; text-align: left; width: 100%;">Parts Notes:</span>

											<v-divider />

											<div 
												class="notes-preview">
												{{ supersede.notes }}
											</div>
										</div>
									</div>								
								</v-tooltip>
							</div>
						</div>
					</div>
				</div>

				<!-- Part Notes Section -->
				<div class="note-details"
					style="margin-right: 5px;"
					v-if="this.partInfo.notes != null && this.partInfo.notes !=''">
					<div class="part-note">
						{{ this.partInfo.notes }}
					</div>
				</div>

				<!-- Part BOM Display Component -->
				<div class="bom">
					<!-- Sub-Components Title Display -->
					<div class="caption">
						<span class="caption-text">Sub-Components:</span>
					</div>

					<!-- Sub-Components List Display -->
					<div class="bom-components"
						v-if="partInfo.bom != null || partInfo.bom.length > 0">
						<!-- Sub-Components Table Header -->
						<div class="header-row elevation-6">
							<div class="header-line">
								<div class="result-part"
									style="height: 100%;">
									<v-spacer />Part Number<v-spacer />
								</div>

								<div class="result-quantity"
									style="height: 100%;">
									<v-spacer />Qty<v-spacer />
								</div>
								
								<div class="result-description"
									style="height: 100%;">
									<v-spacer />Description<v-spacer />
								</div>
								
								<div class="result-edited"
									style="height: 100%;">
								</div>
							</div>
						</div>

						<!-- No Sub-Component Display -->
						<div class="title-full"
							v-if="partInfo.bom != null && partInfo.bom.length == 0">
							<v-spacer />

							There are no sub-components currently configured for this part.

							<v-spacer />
						</div>

						<!-- Recursive BOM Display -->
						<!-- Iterate through all set sub-categories and if sub-category has results, create header with drowpdown
							 functionality for sub-category then iterate through it's parts. -->
						<div class="bom-display"
							v-for="category in subCategories"
							:key="category.id">
							<!-- Show results of the current category -->
							<div v-if="category.results.length > 0">
								<!-- Display for parts that have a set sub-category -->
								<div v-if="category.id != 'DEFAULT'">
									<!-- Sub category header line -->
									<div class="sub-category"
										v-if="category.id != 'DEFAULT'">
										<!-- Part Number Column -->
										<div class="result-part">
											<!-- BOM dropdown button -->
											<div class="dropdown">
												<v-btn
													v-if="category.results.length > 0"
													icon
													large
													@click="toggleCategory(category)">
													
													<v-icon
														color="primary"
														large
														v-if="!category.categoryVisible">
														mdi-arrow-right-drop-circle
													</v-icon>    
													<v-icon
														color="success"
														large
														v-if="category.categoryVisible">
														mdi-arrow-down-drop-circle
													</v-icon>    
												</v-btn>
											</div>

											<!-- Part Number Logic and display -->
											<div class="part-number">
												{{ category.name }}
											</div>
										</div>

										<!-- Quantity Column -->
										<div class="result-quantity centered">
										</div>
										
										<!-- Description Column -->
										<div class="result-description">
											<div>
												Use dropdown on the left to expand sub-category
											</div>
										</div>
										
										<!-- Last Edited Column -->
										<div class="result-edited">
										</div>
									</div>

									<!-- Sub category sub-parts display -->
									<v-expand-transition v-show="category.categoryVisible">
										<div v-if="category.categoryVisible">
											<component
												:is="'Result'"
												v-for="result, index in category.results"
												:key="result.part + '_' + index"
												:item="result"
												:level="1"
												:nextLevel="2">
											</component>
										</div>
									</v-expand-transition>
								</div>

								<!-- Part number isnt part of a set sub-category. -->
								<div v-if="category.id == 'DEFAULT'">
									<component
										:is="'Result'"
										v-for="result, index in category.results"
										:key="result.part + '_' + index"
										:item="result"
										:level="0"
										:nextLevel="1">
									</component>
								</div>
							</div>
						</div>							
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { EventBus } from '../javascript/event-bus'

import Result from './result' 		// Imports result component used to display parts and bom results.

export default {
	name: 'Empty',
	components: {
		Result,
	},
	props: {
		hideSearch: {
			type: Boolean,
			default: false
		},
		partID: {
			type: Number,
			default: -1
		}
	},
	data() {
		return {
			error: '',
			loading: false,
			partNumber: "",
			partDescription: "",
			quantity: 1, 
			partNumbers: [],
			partInfo: {},
			results: [],
			subCategories: [],
			rules: {
				quantity: value => value > 0 || '',
			},
			showPricing: true,
			descriptionSearch: false,
		}
	},
	methods: {
		getParts() {
			if (!this.canSearch) {
				return
			}

			var formData = new FormData();

			this.results = []
			this.loading = true;
			this.partNumber = this.partNumber.trim().toUpperCase()
			this.partDescription = this.partDescription.trim().toUpperCase()
			this.error = ""

			this.clear()

			if (!this.descriptionSearch) {
				formData.append("part", this.partNumber)
				formData.append("description", "")
			} else {
				formData.append("part", "")
				formData.append("description", this.partDescription)
			}

			axios.post(`${this.$config.api}/api.php?cmd=getParts`,
				formData,
				{
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				})
				.then(response => {
					// API FAILED
					if (!response.data.success){
						this.loading = false;

						this.error = "* No results: Part number or description may not be currently unavailable."
						console.log(response.data)
						return
					}

					console.log(response.data)
					this.clear()
					
					// If there is only one result, no need to make the user pick it. Just go straight to loading it.
					if (response.data.results.length == 1) {
						this.searchPart(response.data.results[0].id)
						return				
					}

					//Multiple Results load results variable to show selector modal
					this.results = response.data.results
					this.loading = false
				})
				.catch(error => {
					if (error.response) {
						// The request was made and the server responded with a status code
						// that falls out of the range of 2xx
						console.log("Server returned status code:")
						console.log(error.response.data)
						console.log(error.response.status)
						console.log(error.response.headers)
					} else if (error.request) {
						// The request was made but no response was received
						// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
						// http.ClientRequest in node.js
						console.log("Server didn't respond:")
						console.log(error.request)
					} else {
						// Something happened in setting up the request that triggered an Error
						console.log("Unknown Error:")
						console.log('Error', error.message)
					}
				})
		},
		searchPart(id) {
			if (!this.canSearch && !this.hideSearch) {
				console.log("Search not allowed")
				return
			}

			var formData = new FormData();

			this.results = []
			this.loading = true;
			this.partNumber = this.partNumber.trim().toUpperCase()
			this.error = ""
			this.clear()

			formData.append("id", id)

			if (this.$store.getters["authentication/GET_AUTHENTICATED"]) {
				formData.append("vid", this.$store.getters["authentication/GET_VID"])
			}

			axios.post(`${this.$config.api}/api.php?cmd=searchPart`,
				formData,
				{
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				})
				.then(response => {
					// API FAILED
					if (!response.data.success){
						this.loading = false;

						this.error = `* No results: Database issue: Part Identifier not found.`
						console.log(this.error)
						return
					}

					this.clear()

					//Multiple Results load results variable to show selector modal
					this.partInfo = response.data.result

					if (!this.partNumbers.includes(this.partInfo.part)){
						this.partNumbers.unshift(this.partInfo.part)
						
						if (this.partNumbers.length > 10){
							this.partNumbers = this.partNumbers.slice(0, 10)
						}
					}

					//console.log(response.data)
					this.processSubCategories()
					this.loading = false
					if (!this.hideSearch) {
						this.$refs.partInput.blur()
					}
				})
				.catch(error => {
					if (error.response) {
						// The request was made and the server responded with a status code
						// that falls out of the range of 2xx
						console.log("Server returned status code:")
						console.log(error.response.data)
						console.log(error.response.status)
						console.log(error.response.headers)
					} else if (error.request) {
						// The request was made but no response was received
						// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
						// http.ClientRequest in node.js
						console.log("Server didn't respond:")
						console.log(error.request)
					} else {
						// Something happened in setting up the request that triggered an Error
						console.log("Unknown Error:")
						console.log('Error', error.message)
					}
				})
		},
		processSubCategories() {
			// Clear previous category results
			for (var category of this.subCategories) {
				category.results = []
			}

			for (var bomPart of this.partInfo.bom) {
				var found = false

				// Check all categories for a match
				for (var category of this.subCategories) {
					if (bomPart.category == category.id) {
						category.results.push(bomPart)
						found = true
					}
				}

				// No valid sub-category found add to default
				if (!found) {
					this.subCategories[0].results.push(bomPart)
				}
			}
		},
		showUpdate() {
			this.$emit('showUpdate', true)
		},
		clear() {
			this.partInfo = {}
			this.results = []
		},
		copyLink() {
			var link = `${this.$dealer}?part=${this.partInfo.part}`

			navigator.clipboard.writeText(link)

			alert(`Link to part search created and copied to clipboard`)
		},
		sellableStyle(sellable) {
			if (sellable == 1) {
				return "color: black;cursor: pointer;"
			}

			return "color: red;cursor: pointer;"
		},
		supersedeImageAddress(image){
			return `${this.$config.images}${image}`
		},
		partClicked(id, part) {
			if (part.length > 0){
				this.searchPart(id)
			}
		},
		showPDF(pdf) {
			if (pdf == null || pdf == -1) {
				return
			}

			EventBus.$emit('showPDF', pdf)
		},
		toggleCategory(category) {
			category.categoryVisible = !category.categoryVisible
		},
		addToCart() {
			if (this.quantity < 1) {
				return
			}

			this.$store.dispatch("cart/ADD_PART", {
					part: this.partInfo.part,
					quantity: this.quantity,
					description: this.partInfo.description,
					retail: parseFloat(this.partInfo.retail).toFixed(2),
					dealer: parseFloat(this.calculateDealer(this.partInfo.retail)).toFixed(2)
				})

			this.$cookie.set("cart", JSON.stringify(this.$store.getters["cart/GET_CART"]), 14)
		},
		ChangeShowPricing() {
			this.$store.dispatch('cart/SET_SHOW_PRICING', this.showPricing)
			EventBus.$emit('showPricingChanged')
		},
		setShowPricing() {
			this.showPricing = this.$store.getters['cart/GET_SHOW_PRICING']
		},
		calculateDealer(retail) {
			return retail * this.$store.getters["cart/GET_DISCOUNT"].decimal
		},
		quantityChanged() {
			if (this.quantity < 1) {
				this.quantity = 1
			}
		},
	},
	computed: {
		canSearch() {
			if (!this.descriptionSearch && this.partNumber != null && this.partNumber.length >= 2){
				return true
			}

			if (this.descriptionSearch && this.partDescription != null && this.partDescription.length >= 2){
				return true
			}

			return false
		},
        imageAddress() {
            if (this.partInfo.image.length > 0){
                return `${this.$config.images}${this.partInfo.image}`
            }

            return ''
        },
		sellable() {
			if (this.partInfo.sellable == 0) {
				return "discontinued"
			}

			return ""
		},
		hasNotes() {
			if (this.partInfo.notes != null && this.partInfo.notes.length > 0){
				return ""
			}

			return "background-color: whitesmoke;"
		},
		background() {
			if (this.partInfo.part != null && this.partInfo.part.length == 0) {
				return ""
			}

			return "background-color: white;"
		},
		showCart() {
			return this.partInfo.sellable == 1 && this.$store.getters['authentication/GET_CREDENTIALS'].authenticated	
		},
	},
	mounted: function() {
		EventBus.$on('showPricingChanged', this.setShowPricing)
		this.showPricing = this.$store.getters['cart/GET_SHOW_PRICING']

		// Create a new instance of the config's sub-categories.
		this.subCategories = [...new Set(this.$config.serial.subCategories.map(JSON.stringify))].map(JSON.parse)

		if (!this.hideSearch) {
			// Check the URL for part parameter.
			var urlParams = new URLSearchParams(window.location.search)

			if(urlParams.has('part')){
				this.partNumber = urlParams.get('part')

				if(this.partNumber != null && this.partNumber.length > 0){
					this.getParts()
				} else {
					console.log(`Invalid value for part parameter: ${this.partNumber}`)
					this.partNumber = ''
				}
			}
		} else {
			if (this.partID != -1) {
				this.searchPart(this.partID)
			} else {
				console.log(`Bad part ID for search disabled mode: ${this.partID}`)
			}
		}
	},	
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	.part-search {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 100%;
		overflow: hidden;
		font-size: 2vh;
	}

	.loading {
		position: absolute;
		top: 0px;
		left: 0px;
		background-color: rgba(0,0,0,.25);
		height: 100%;
		width: 100%;
		text-align: center;
		z-index: 5;
	}

	.progress {
		position: relative;
		top: 40%;
	}

	.discontinued {
		color: red;
	}

	.search-bar {
		display: flex;
		flex-direction: row;
		align-items: center;
		flex-grow: 0;
		border: 1px solid silver;
		border-radius: 5px;
		padding: 5px;
		height: 50px;
		width: 100%;
		background-color: white;
	}

	.part-info {
		display: flex;
		flex-direction: row;
		flex-grow: 1;
		width: 100%;
		height: 100%;
		border: 1px solid darkgrey;
		border-radius: 5px;
		margin-top: 5px;
		padding: 5px;
		overflow: hidden;
	}

	.field {
		max-width: 250px;
	}

	.button {
		margin-left: 10px;
	}

	.options-container {
		display: flex;
		z-index: 100;
		position: absolute;
		height: 100%;
		width: 100%;
		left: 0px;
		top: 0px;
		background-color: rgba(0,0,0,.25);
		text-align: center;
		padding-top: 10vh;
	}

	.options-inner {
		display: flex;
		flex-direction: column;
		max-height: 400px;
		width: 700px;
		overflow: hidden;
		border: 1px solid black;
		border-radius: 5px;
		background-color: white;
	}

	.close-button {
		float: right;
		top:5px;
		right: 0px;
	}

	.options-title {
		font-size: 18pt;
		flex-shrink: 1;
		background-color: lightblue;
		border-bottom: 1px solid black;
	}

	.options-results {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		border: 1px solid darkgrey;
		margin: 5px;
		padding: 5px;
		overflow-y: scroll;
	}

	.options-result {
		display: flex;
		flex-direction: row;
		align-items: center;
		border: 1px solid silver;
		width: 100%;
	}

	.options-result:hover {
		border: 1px solid lightgreen;
		background-color: lightcyan;
		cursor: pointer;
	}

	.option-part {
		padding: 5px;
		width: 20%;
		border-right: 1px solid silver;
		text-align: left;
	}

	.option-description {
		padding: 5px;
		width: 80%;
		text-align: left;
	}

	.preview-image {
		object-fit: contain;
	}

	.preview-image-box {
		display: flex;
		align-items: center;
		text-align: center;
		max-width: 20vw;
		padding: 5px;
		margin-bottom: 5px;
		border: 1px solid darkgrey;
		border-radius: 5px;
	}

	.part-details {
		display: flex;
		flex-direction: column;
		width: 100%;
		padding: 5px;
		border: 1px solid darkgrey;
		border-radius: 5px;
		margin-bottom: 5px;
	}

	.note-details {
		display: flex;
		flex-direction: column;
		flex-shrink: 1;
		width: 100%;
		margin-bottom: 5px;
	}

	.caption {
		display: flex;
		align-items: center;
		padding: 5px;
		width: 100%;
		height: 4vh;
		background-color: aliceblue;
		font-weight: bold;
		border: 1px solid black;
		overflow: hidden;
	}

	.caption-text {
		font-size: 2vh;
	}

	.title-full {
		display: flex;
		align-items: center;
		padding: 5px;
		width: 100%;
		height: 100%;
		background-color: aliceblue;
		font-size: 24pt;
		border: 1px solid black;
	}

	.info-line {
		display: flex;
		align-items: center;
		flex-direction: row;
		width: 100%;
		height: 52px;
		font-size: 2vh;
	}

	.line-title {
		display: flex;
		align-items: center;
		padding: 5px;
		width: 150px;
		height: 100%;
		background-color: lightcyan;
		border: 1px solid darkgrey;
		font-size: 2vh;

	}

	.line-info {
		display: flex;
		align-items: center;
		flex-grow: 1;
		padding: 5px;
		height: 100%;
		border: 1px solid darkgrey;
		font-size: 2vh;
	}

	.part-note {
		white-space: pre-wrap;
		padding: 5px;
		border: 1px solid darkgrey;
		border-radius: 5px;
		background-color: antiquewhite;
		height: 100%;
		width: 100%;
	}

	.bom {
		display: flex;
		flex-direction: column;
		width: 100%;
		overflow: auto;
		padding: 5px;
		border: 1px solid darkgrey;
		border-radius: 5px;
	}

	.bom-components {
		display: flex;
		flex-direction: column;
		height: 100%;
		width: 100%;
		overflow-y: scroll;
		overflow-x: hidden;
	}

	.bom-display {
		margin-left: 1px;
		margin-right: 1px;
	}

	.top-level {
		margin-top: 5px;
	}

	.header-row {
		display:flex;
		flex-direction: row;
		width: 100%;
		min-height: 5vh;
		font-weight: 600;
 		background-color: #b3f0ff; 
		border: 1px solid darkgray;
 	}

	.header-line {
		display:flex;
		flex-direction: row;
		align-items: center;
		width: 100%;
		min-height: 5vh;
		font-size: 2vh;
	}

	.result-part {
		display: flex;
		flex-direction: row;
		align-items: center;
		width: 30%;
		border: 1px solid #4d4d4d;
		padding: 5px;
	}

	.result-quantity {
		display: flex;
		flex-direction: row;
		align-items: center;
		width: 5%;
		border: 1px solid #4d4d4d;
		padding: 5px;
		overflow: hidden;
	}

	.result-description {
		display: flex;
		flex-direction: row;
		align-items: center;
		width: 50%;
		border: 1px solid #4d4d4d;
		padding: 5px;
	}

	.result-edited {
		width: 15%;
		border: 1px solid #4d4d4d;
		padding: 5px;
	}

	.centered {
		text-align: center;
	}

	.no-bom {
		display: flex;
		flex-direction: row;
		align-items: center;
		border: 1px solid darkgrey;
		width: 100%;
		height: 100%;
	}

	.notes-preview {
		white-space: pre-wrap;
		border: 1px solid darkgrey;
		border-radius: 5px;
		padding: 5px;
		overflow-x: hidden;
		overflow-y: auto;
		width: 100%;
		min-height: 100px;
		max-height: 300px;
		background-color: lightyellow;
		color: black;
		font-size: 18pt;
		max-width: 1000px;
	}

	.notes {
		border: 1px solid black;
		border-left: 2px solid black;
		padding: 5px;
		overflow-x: hidden;
		overflow-y: auto;
		width: 100%;
		max-height: 60px;
		min-height: 30px;
		background-color: lightyellow;
		color: black;
		font-size: 12pt;
		font-weight: 400;
	}

	.column {
		display: flex;
		flex-direction: column;
		align-items: center;
		border: 2px solid darkgrey; 
		border-radius: 10px;
		padding: 5px;
		background-color: white;
	}

	.title2 {
		font-size: 24pt;
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.search-buttons {
		display: flex;
		flex-direction: row;
		align-items: center;
		flex-grow: 1;
		padding-left: 5px;
		padding-right: 5px;
	}

	.close-button-float {
		position: absolute;
		top:12px;
		right: 15px;
	}

	.left-column {
		display: flex;
		flex-direction: column;
		flex-shrink: 1;
		height: 100%;
		min-width: 20vw;
		overflow: hidden;
		margin-right: 5px;
	}

	.right-column {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		height: 100%;
		overflow: hidden;
	}

	.sub-category {
		display:flex;
		flex-direction: row;
		width: 100%;
		min-height: 25px;
		font-size: 2vh;
		background-color: rgba(0,0,0,0.05);
	}

	.dropdown {
		min-width: 40px;
		text-align: right;
	}

	.add-to-cart {
		display: flex;
		flex-direction: row;
		align-items: center;
		height: 100%;
		margin-left: 10px;
		border-left: 1px solid silver;
		padding: 5px;
		padding-left: 15px;
	}

	.show-pricing {
		height: 100%;
		margin-top: -10px;
	}

	.pricing {
		width: 100%;
		font-size: 1.65vh;
	}

	.search {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-right: 5px;
	}

</style>